import Resources from "../images/Resources.svg";
import Setting from "../images/setting.svg";
import Graits from "../images/Graits.svg";
import Money from "../images/Money.png";
import Other from "../images/Other.svg";
import everyOne from "../images/everyone.svg";
import person from "../images/person.svg";

export const dataWhat = [
  {
    value: "Graits",
    text: "Graits",
    imgurl: Graits,
  },
  {
    value: "Other",
    text: "Other",
    imgurl: Other,
  },
  {
    value: "Skills",
    text: "Skills",
    imgurl: Setting,
  },
  {
    value: "Money",
    text: "Money",
    imgurl: Money,
  },
  {
    value: "Resources",
    text: "Resources",
    imgurl: Resources,
  },
];

export const data = [
  {
    value: "everyone",
    text: "everyone",
    imgurl: everyOne,
  },
  {
    value: "person",
    text: "person",
    imgurl: person,
  },
];
