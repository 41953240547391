import React, { useState } from "react";
import Style from "../Styles/stickyFooter.module.css";
import Plus from "../images/plus.svg";
import ModalComponent from "./ModalCompnent";
import Event from "../images/event.svg";
import Offer from "../images/OfferBlack.svg";
import Request from "../images/Request.svg";
import Graits from "../images/Graits.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SignUpMemberShipComponent from "./SignUpMemberShipComponent";
import { packageListApi } from "../api/services";
import Loader from "./Loader";
import ToasterComponent from "./ToasterComponent";
import OfferPostListComponent from "./OfferPostComponent";

function findObjectByKey(array, key, value) {
  return array.find((obj) => obj[key] === value);
}

function StickyFooter() {
  const [showModal, setShowModal] = useState(false);
  const [EventProduce, setEventProduce] = useState(false);
  const [isProduceMemberShip, setProduceSetUpMemberShip] = useState(false);
  const [ModalOfferPost, setModalCloseOfferPost] = useState(false);
  const [currentFocus, setCurrentFocus] = useState(null);

  const [select, setSelectData] = useState({});
  const isCreateEventEnabled = useSelector(
    (state) => state?.showTicketCheckins?.isCreateEventEnabled
  );

  const state = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const callPackageListAPi = async () => {
    setIsLoading(true);
    try {
      const response = await packageListApi();
      const { data } = response.data;
      const result = findObjectByKey(data, "key", "event_producer");
      setSelectData(result);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const OnEventBtnClick = () => {
    if (state.userInfo.userData) {
      if (isCreateEventEnabled) {
        navigate("/create-event");
        setShowModal(false);
      } else {
        callPackageListAPi();
        setShowModal(false);
        setEventProduce(true);
      }
    } else {
      ToasterComponent("Login is required to createEvent", 2000);
    }
  };

  return (
    <>
      <div className={Style.stickyFooter}>
        <span className={Style.fonts} onClick={openModal}>
          <img src={Plus} className={Style.img} alt="View Option" />
        </span>
      </div>
      <ModalComponent
        hideFunc={closeModal}
        show={showModal}
        wrapperClassname={` ${Style.wModal}`}
        isHeaderHight
        body={
          <div style={{ display: "flex", gap: 10, flexDirection: "column" }}>
            <div className={Style.subContainer} onClick={OnEventBtnClick}>
              <span className={Style.greenBorder}>
                <img src={Event} className={Style.ModalImg} alt="Event" />
                <span>Event</span>
              </span>
            </div>
            <div className={Style.subContainer}>
              <span
                className={Style.greenBorder}
                onClick={() => {
                  setModalCloseOfferPost(true);
                  setCurrentFocus("offer");
                }}
              >
                <img src={Offer} className={Style.ModalImg} alt="Offer" />
                <span>Offer</span>
              </span>
              <span
                className={Style.greenBorder}
                onClick={() => {
                  setModalCloseOfferPost(true);
                  setCurrentFocus("request");
                }}
              >
                <img src={Request} className={Style.ModalImg} alt="Request" />
                <span>Request</span>
              </span>
              <span
                className={Style.greenBorder}
                onClick={() => {
                  setModalCloseOfferPost(true);
                  setCurrentFocus("gratis");
                }}
              >
                <img src={Graits} className={Style.ModalImg} alt="Graits" />
                <span>Graits</span>
              </span>
            </div>
          </div>
        }
      />
      <SignUpMemberShipComponent
        setEventProduce={setEventProduce}
        select={select}
        setProduceSetUpMemberShip={setProduceSetUpMemberShip}
        EventProduce={EventProduce}
        isProduceMemberShip={isProduceMemberShip}
      />

      <OfferPostListComponent
        setModalClose={setModalCloseOfferPost}
        Modal={ModalOfferPost}
        currentFocus={currentFocus}
        setCurrentFocus={setCurrentFocus}
      />

      {isLoading && <Loader />}
    </>
  );
}

export default StickyFooter;
