import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={29}
    height={26}
    fill="none"
    {...props}
  >
    <path
      fill="#AAD3A4"
      stroke={props.stroke ? props.stroke : "#AAD3A4"}
      strokeWidth={0.5}
      d="M27.28 18.076a2.241 2.241 0 0 0-1.665-.203 850.47 850.47 0 0 0-6.714 1.856c.14-1.081-.471-1.844-1.889-2.335-.19-.053-4.687-1.303-6.995-1.676-1.466-.237-8.05 1.855-8.795 2.095a.303.303 0 0 0-.204.39c.058.161.244.245.416.192 2.866-.92 7.487-2.232 8.472-2.071 2.27.366 6.873 1.646 6.9 1.653 1.129.392 1.56.904 1.441 1.712a1.518 1.518 0 0 1-.656 1.02 1.76 1.76 0 0 1-1.157.269l-.485-.064-6.158-1.035a.326.326 0 0 0-.38.248c-.032.167.086.327.264.357l6.17 1.037.505.066a2.35 2.35 0 0 0 1.623-.378 2.15 2.15 0 0 0 .701-.778l.015-.001c.043-.012 4.353-1.223 7.107-1.966a1.552 1.552 0 0 1 1.153.143c.386.211.667.566.771.975.03.116.046.235.046.352 0 .585-.366 1.122-.928 1.365l-1.03.432-5.372 1.865c-.913.318-2.349.576-3.328.45L6.054 22.388c-.117-.022-.589-.111-.79-.115-.02 0-.047.005-.072.009-.006.001-.011 0-.017.002-.634.114-2.598 1.383-3.74 2.158a.295.295 0 0 0-.074.43.341.341 0 0 0 .458.069c1.396-.948 3.1-1.995 3.425-2.051l11.765 1.762.033.004c.199.025.41.036.629.036 1.027 0 2.209-.247 2.993-.521l5.391-1.872 1.053-.441c.798-.345 1.314-1.1 1.314-1.925 0-.166-.022-.334-.064-.497a2.186 2.186 0 0 0-1.079-1.361ZM18.649 15.84l.123.046.122-.047c.246-.092 6.047-2.316 8.647-6.184 1-1.489 1.156-3.293.436-5.082-.663-1.647-2.015-3.004-3.365-3.378-1.247-.344-2.51-.225-3.557.336-1.018.545-1.81 1.487-2.325 2.751-.516-1.302-1.297-2.257-2.29-2.79-.995-.533-2.166-.635-3.387-.297-1.296.359-2.566 1.602-3.237 3.168-.766 1.788-.657 3.713.3 5.28 2.32 3.801 8.28 6.1 8.533 6.196ZM10.424 4.591c.6-1.398 1.704-2.5 2.814-2.807.408-.113.809-.17 1.195-.17.604 0 1.172.138 1.68.41 1.066.572 1.86 1.736 2.297 3.368l.317 1.19.318-1.19c.425-1.586 1.232-2.737 2.335-3.328.89-.478 1.973-.577 3.046-.28 1.163.322 2.344 1.53 2.938 3.006.373.928.821 2.753-.377 4.536-2.284 3.398-7.36 5.55-8.215 5.894-.866-.35-6.057-2.558-8.087-5.883-.857-1.404-.952-3.133-.26-4.746Z"
    />
  </svg>
);
export default SvgComponent;
