import React from "react";
import PostComment from "./PostComment";

function PostCommentList() {
  return (
    <>
      <PostComment />
    </>
  );
}

export default PostCommentList;
