import React, { useState, useEffect } from "react";
import io from "socket.io-client";

const URL = "https://eventmvpnode.developmentlabs.co/";
// https://eventmvpnode.developmentlabs.co/api/socket.io/

const SocketComponent = () => {
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    // Cleanup function to disconnect the socket when component unmounts
    return () => {
      if (socket) {
        socket.disconnect();
        setSocket(null);
        setIsConnected(false);
      }
    };
  }, [socket]);

  const connectToSocket = () => {
    const newSocket = io(URL, {
      path: "/api/socket.io/",
      withCredentials: true,

      // path: "/api/socket.io/",
    }); // Replace 'http://your-socket-url' with your actual socket URL
    newSocket.on("connect", () => {
      setSocket(newSocket);
      setIsConnected(true);
      console.log("CONNECTED=>", newSocket.id); // x8WIv7-mJelg7on_ALbx
    });

    newSocket.on("Hello", async (msg) => {
      console.log("msg=>", msg); // x8WIv7-mJelg7on_ALbx
    });

    newSocket.on("connect_error", (error) => {
      setHasError("Socket connection error:");
      console.error("Socket connection error:", error);
    });
  };

  const disconnectFromSocket = () => {
    if (socket) {
      socket.disconnect();
      setSocket(null);
      setIsConnected(false);
    }
  };

  return (
    <div>
      <div>Socket Status: {isConnected ? "Connected" : "Disconnected"}</div>
      <button onClick={connectToSocket} disabled={isConnected}>
        Connect
      </button>
      <button onClick={disconnectFromSocket} disabled={!isConnected}>
        Disconnect
      </button>
      {hasError && <p> {hasError}</p>}
    </div>
  );
};

export default SocketComponent;
