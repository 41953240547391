import React from "react";
import PostStyle from "../../Styles/Post.module.css";

function PostComment() {
  return (
    <div className={PostStyle.postComment}>
      <div className={PostStyle.postCommentUser}>
        <img src="" alt="user" />
      </div>
      <div></div>
    </div>
  );
}

export default PostComment;
