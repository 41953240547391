import React from "react";
import RightArrow from "../Components/SVGR/RightArrow";
import { type } from "@testing-library/user-event/dist/type";
import Style from "../Styles/CustomButtonComponent.module.css";

function Button(props) {
  return (
    <button className={Style.btnWrapper} onClick={props.onClick}>
      <div style={{ display: "flex" }}>
        <span
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Post Offer
        </span>
        <div style={{}}>
          <RightArrow fill="#BF820A" />
        </div>
      </div>
    </button>
  );
}

export default Button;
