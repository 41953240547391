import React, { useState } from "react";
import PostStyle from "../Styles/Post.module.css";
import profileImg from "../images/post-profile-default.png";
import ToasterComponent from "./ToasterComponent";
import ToasterSuccess from "./ToasterSuccess";
import likeImgOutline from "../images/like-img-outline.svg";
import commentImg from "../images/comment-img.svg";
import { submitPostComment } from "../api/services";
import PostCommentList from "./post/PostCommentList";
import { useSelector } from "react-redux";

function PostListLayoutCard({ postData, loaderFunc }) {
  const [showAddComment, setShowAddComment] = useState(false);
  const [commentText, setCommentText] = useState("");

  const handleShowComment = () => {
    setShowAddComment(!showAddComment);
  };

  const handleChangeComment = (e) => {
    setCommentText(e.target.value);
  };

  const handleAddComment = async () => {
    loaderFunc(true);
    try {
      const createResponse = await submitPostComment({
        postId: postData?.id,
        content: commentText,
        // comment_id: null,
      });
      if (createResponse?.success) {
        ToasterSuccess(createResponse?.message, 2000);
        setShowAddComment(false);
      } else {
        ToasterComponent(
          createResponse?.message || "Something went wrong",
          2000
        );
      }
    } catch (error) {
      ToasterComponent(error?.message || "Something went wrong", 2000);
    } finally {
      loaderFunc(false);
    }
  };

  return (
    <div className={PostStyle.postCardMainDiv}>
      <div className={PostStyle.authorSection}>
        <div className={PostStyle.authorDetail}>
          <div>
            <img
              src={postData?.user_id?.pic}
              alt="event"
              className={PostStyle.authorImg}
            />
          </div>
          <div>
            <div>{`${postData?.user_id?.first_name} ${postData?.user_id?.last_name}`}</div>
            <div className={PostStyle.authorTime}>{postData?.date}</div>
          </div>
        </div>

        <div className={PostStyle.topSection}>
          {/* <span>$4500</span> */}
          {/* <span>
            <img
              src={likeImg}
              alt="like"
              className={PostStyle.topSectionLikeImg}
            />
          </span> */}
        </div>
      </div>
      <div className={PostStyle.postContent}>{postData?.content}</div>
      <div className={PostStyle.postImageSection}>
        <img
          src={postData?.image[0]}
          alt="post-image"
          className={PostStyle.postImage}
        />
      </div>
      <div className={PostStyle.likeCommentSectionLable}>
        <div>
          <span className={PostStyle.likeCount}>
            {postData?.gratis ? `+${postData?.gratis}` : "0"}
          </span>
          <span>
            <img
              src={likeImgOutline}
              alt="like"
              className={PostStyle.likeCountImg}
            />
          </span>
        </div>
        <div>
          <span>{postData?.comment}</span>
          <span>
            <img
              src={commentImg}
              alt="comment"
              className={PostStyle.commentCountImg}
            />
          </span>
        </div>
      </div>
      <div className={PostStyle.likeCommentSection}>
        <div className={PostStyle.likeCommentLeftSection}>
          <img
            src={likeImgOutline}
            alt="like"
            className={PostStyle.likeSectionImg}
          />
        </div>
        <div
          className={PostStyle.likeCommentRightSection}
          onClick={handleShowComment}
        >
          Comment
        </div>
      </div>
      <PostCommentList />
      {showAddComment && (
        <div className={PostStyle.commentBox}>
          <textarea
            className={PostStyle.commentBoxInput}
            placeholder="Make a comment"
            value={commentText}
            onChange={handleChangeComment}
          />
          <button
            className={PostStyle.commentBoxBtn}
            onClick={handleAddComment}
          >
            Add Comment
          </button>
        </div>
      )}
    </div>
  );
}

export default PostListLayoutCard;
