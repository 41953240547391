import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M18.13 0H1.87C.839 0 0 .84 0 1.87v9.343c0 1.033.84 1.87 1.87 1.87h16.26c1.032 0 1.87-.84 1.87-1.87V1.87C20 .838 19.16 0 18.13 0Zm1.325 11.213c0 .73-.595 1.325-1.326 1.325H1.871c-.731 0-1.326-.594-1.326-1.325V1.87c0-.731.595-1.326 1.326-1.326h16.258c.731 0 1.325.595 1.325 1.326v9.342Z"
    />
    <path
      fill="#000"
      d="M3.452 8.023H6.43a.894.894 0 0 0 .892-.893V5.344a.894.894 0 0 0-.892-.893H3.452a.894.894 0 0 0-.892.893V7.13a.894.894 0 0 0 .892.893Zm-.297-2.679a.298.298 0 0 1 .297-.298H6.43a.298.298 0 0 1 .297.298V7.13a.298.298 0 0 1-.297.297H3.452a.298.298 0 0 1-.297-.297V5.344ZM2.262 9.512a.298.298 0 0 0-.595 0v.596a.298.298 0 0 0 .595 0v-.596ZM2.857 10.405a.297.297 0 0 0 .298-.297v-.596a.298.298 0 0 0-.595 0v.596a.298.298 0 0 0 .297.297ZM3.75 9.215a.298.298 0 0 0-.298.297v.596a.298.298 0 0 0 .596 0v-.596a.298.298 0 0 0-.298-.297ZM4.643 9.215a.298.298 0 0 0-.298.297v.596a.298.298 0 0 0 .595 0v-.596a.298.298 0 0 0-.297-.297ZM6.428 9.215a.298.298 0 0 0-.297.297v.596a.298.298 0 0 0 .595 0v-.596a.298.298 0 0 0-.298-.297ZM7.024 9.512v.596a.298.298 0 0 0 .595 0v-.596a.298.298 0 1 0-.595 0ZM8.214 10.405a.297.297 0 0 0 .298-.297v-.596a.298.298 0 0 0-.595 0v.596a.298.298 0 0 0 .297.297ZM9.107 10.405a.297.297 0 0 0 .298-.297v-.596a.298.298 0 0 0-.595 0v.596a.298.298 0 0 0 .297.297ZM10.893 10.405a.299.299 0 0 0 .297-.297v-.596a.298.298 0 0 0-.595 0v.596a.299.299 0 0 0 .298.297ZM11.786 10.405a.3.3 0 0 0 .275-.183.297.297 0 0 0 .022-.114v-.596a.298.298 0 0 0-.595 0v.596a.297.297 0 0 0 .298.297ZM12.678 9.215a.297.297 0 0 0-.297.297v.596a.298.298 0 1 0 .595 0v-.596a.299.299 0 0 0-.298-.297ZM13.572 9.215a.298.298 0 0 0-.298.297v.596a.298.298 0 0 0 .595 0v-.596a.297.297 0 0 0-.297-.297ZM15.357 9.215a.297.297 0 0 0-.297.297v.596a.298.298 0 1 0 .595 0v-.596a.299.299 0 0 0-.298-.297ZM16.25 9.215a.297.297 0 0 0-.298.297v.596a.297.297 0 1 0 .596 0v-.596a.299.299 0 0 0-.298-.297ZM17.143 9.215a.297.297 0 0 0-.298.297v.596a.298.298 0 0 0 .596 0v-.596a.297.297 0 0 0-.298-.297ZM17.738 9.512v.596a.298.298 0 0 0 .595 0v-.596a.298.298 0 0 0-.595 0ZM11.786 3.261h5.952a.298.298 0 1 0 0-.595h-5.952a.298.298 0 1 0 0 .595ZM14.762 4.453h2.083a.298.298 0 0 0 0-.596h-2.083a.298.298 0 1 0 0 .596ZM17.143 5.344a.299.299 0 0 0-.298-.297h-2.083a.298.298 0 1 0 0 .595h2.083a.297.297 0 0 0 .298-.298Z"
    />
  </svg>
);
export default SvgComponent;
